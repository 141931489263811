<template>

  <div class="main-page">

    <!--====== Banner Start ======-->
    <section class="breadcrumb-area">
      <div class="container">
        <div class="content">
          <h2 class="breadd  wow fadeInUp" id="title-1">
            【源码】{{ goodObj.goodsName }}
          </h2>
          <ul class="breadcrumb-list  wow fadeInUp">
            <li><a>首页 /</a></li>
            <li>项目详情</li>
          </ul>
        </div>
      </div>
    </section>
    <!--====== Banner End ======-->

    <!--====== 付费项目内容 Start  ======-->
    <section>
      <div class="container">
        <div class="row wow fadeInUp" style="padding-top: 30px">
          <div class="col-xl-9">
            <div class="detsils-box">
              <el-card class="wow fadeInUp" style="padding-bottom: 20px;">
                <div class="goods-header-img">
                  <div style="width: 490px;height: 280px;background-color: #eeeeee;text-align: center">
                    <el-image v-for="(imgUrl,index) in [goodObj.imgPath]"
                              style="height: 280px;width: auto;max-width:490px;margin: 0px auto;"
                              class="comment-content-img"
                              :initial-index="index"
                              :src="baseUrl+imgUrl"
                              :preview-src-list="[baseUrl+goodObj.imgPath]">
                    </el-image>
                  </div>
                </div>
                <div class="goods-header-info">
                  <div>
                    <h5 style="line-height: 34px">
                      【{{ goodObj.goodsCode }}】{{ goodObj.goodsName }}</h5>
                  </div>
                  <div style="padding-left: 10px">
                    <p class="i-box" style="margin-bottom: 5px !important;">
                      <!--                      <el-rate disabled v-model="rate"></el-rate>-->
                      <el-rate
                          v-model="goodObj.score"
                          disabled
                          show-score
                          text-color="#ff9900"
                          score-template="{value}分">
                      </el-rate>
                    </p>
                    <el-tag :key="tag" v-for="tag in goodObj.techNames"
                            style="margin-right: 5px">
                      {{ tag }}
                    </el-tag>
                  </div>
                  <div style="padding-left: 10px;margin-top: 8px;">
                    <div class="st">源码售价：</div>
                    <span class="subtitle">￥{{ numberToFixed(goodObj.currentPrice, 2) }}</span>
                    <span
                        style="font-size: 16px;text-decoration: line-through;margin-left: 5px;color: #8a8f9c;">￥{{
                        numberToFixed(goodObj.originalPrice, 2)
                      }}</span>
                  </div>
                  <div style="padding-left: 10px;margin-top: 8px;">
                    <div class="st">选择项目：</div>
                    <div class="sc">
                      <el-radio-group v-model="goodsCode" size="mini" @change="toDetails">
                        <el-radio v-for=" gg in goodsGroup" :label="gg.goodsCode"
                                  border>{{ gg.nickName }}
                        </el-radio>

                      </el-radio-group>
                    </div>

                  </div>


                  <div style="padding-left: 10px;margin-top: 8px;" v-for="(items, serveCode) in groupServe"
                       :key="serveCode">
                    <div class="st">{{ items[0].serveTypeName }}：</div>
                    <div class="sc">
                      <el-radio-group v-model="groupServeValue[serveCode]" size="mini">
                        <el-tooltip placement="bottom" effect="light" :disabled="!item.remark" v-for="item in items">
                          <div slot="content" style="width: 160px">{{ item.remark }}</div>
                          <el-radio :label="item.code" :key="item.code"
                                    @click.native.stop.prevent="changeServe(item.code,serveCode)" border>{{
                              item.name
                            }}
                            <span> ￥{{ item.price }}</span></el-radio>
                        </el-tooltip>
                      </el-radio-group>
                    </div>
                  </div>


                  <div class="goods-header-buy">
                    <el-button type="danger" @click="buy()">
                      立即获取
                    </el-button>
                  </div>

                </div>

              </el-card>
              <el-card style="margin-top:10px;margin-bottom:15px;">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                  <el-tab-pane label="项目详情" name="first">
                    <div class="markdown-body"
                         v-html="goodObj.content" @click="imageEnlargement">
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="文档结构" name="six" v-if="isTree">
                    <div class="markdown-body">
                      <el-tree :data="goodObj.tree" :default-expanded-keys="dataIdArr" node-key="dataId"
                               style="margin-top: 3px"
                               icon-class="">
                      </el-tree>
                    </div>
                  </el-tab-pane>

                  <el-tab-pane label="演示视频" name="third" v-if="goodObj.runVideo">
                    <div class="details-video" v-if="activeName=='third'">
                      <iframe
                          :src="'//player.bilibili.com/player.html?bvid='+goodObj.runVideo.split('?')[0].replace('https://www.bilibili.com/video/','').replace('/','')"
                          scrolling="no" border="0" frameborder="no" framespacing="0"
                          allowfullscreen="true"></iframe>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="安装视频" name="second"
                               v-if="goodObj.ideaVideo">
                    <div class="details-video" v-if="activeName=='second'">
                      <iframe
                          :src="'//player.bilibili.com/player.html?bvid='+goodObj.ideaVideo.split('?')[0].replace('https://www.bilibili.com/video/','').replace('/','')"
                          scrolling="no" border="0" frameborder="no" framespacing="0"
                          allowfullscreen="true"></iframe>
                    </div>
                  </el-tab-pane>
                  <!--                  <el-tab-pane :label="'用户评论（'+totalCount+'）'" name="fourth" class="comment-info">-->
                  <!--                    <el-card class="comment-main" v-for="comment in commentList">-->
                  <!--                      <div class="comment-user">-->
                  <!--                        <img src="@/static/image/user-logo.jpg" width="80px" alt="">-->
                  <!--                        <p>{{ comment.userNickName }}</p>-->
                  <!--                      </div>-->
                  <!--                      <div class="comment-content">-->
                  <!--                          <span class="i-box"><el-rate :disabled="true" v-model="comment.score"-->
                  <!--                                                       allow-half></el-rate> </span>-->
                  <!--                        <p>{{ comment.content }}</p>-->
                  <!--                      </div>-->
                  <!--                      <div class="comment-content">-->
                  <!--                        <div style="min-width: 204px;min-height: 1px">-->
                  <!--                          <el-image v-for="(imgUrl,index) in commentImgList(comment.imgInfo)"-->
                  <!--                                    class="comment-content-img"-->
                  <!--                                    :initial-index="index"-->
                  <!--                                    :src="imgUrl"-->
                  <!--                                    :preview-src-list="commentImgList(comment.imgInfo)">-->
                  <!--                          </el-image>-->
                  <!--                        </div>-->
                  <!--                        <div>-->
                  <!--                          <p style="font-size: 10px;margin-top: 45px">{{-->
                  <!--                              comment.createTime-->
                  <!--                            }}</p>-->
                  <!--                        </div>-->
                  <!--                      </div>-->
                  <!--                    </el-card>-->

                  <!--                    <template v-if="totalCount>0">-->
                  <!--                      <el-pagination-->
                  <!--                          class="pagination"-->
                  <!--                          background-->
                  <!--                          layout="prev, pager, next"-->
                  <!--                          @current-change="pageNoHandle"-->
                  <!--                          :page-count="totalPage">-->
                  <!--                      </el-pagination>-->
                  <!--                    </template>-->
                  <!--                    <template v-else>-->
                  <!--                      <el-empty :image-size="80"></el-empty>-->
                  <!--                    </template>-->
                  <!--                  </el-tab-pane>-->
                  <el-tab-pane label="售后保障" name="five">
                    <div class="col-xl-12 col-lg-12">
                      <div class="box-one">
                        <el-card class="box-card" style="margin: 20px 0px 30px;">
                          <div slot="header" class="clearfix">
                            <span style="font-size: 18px;font-weight: 600">项目购买承诺</span>
                          </div>
                          <div>
                            <p style="text-indent: 2em;line-height:30px">
                              项目都是经过我们开发人员进行调试，可以<strong
                                style="color: #0069d9">100%确保成功运行</strong>且具备相对较为完善的功能，还请您放心购买，
                              购买后项目包含相关文档以及客服联系方式。如有<strong
                                style="color: #0069d9">任何疑问都可联系客服</strong>，包括项目业务咨询、技术咨询、工作面试、技术栈学习、课程设计、毕业设计等等，知无不答。
                            </p>
                          </div>
                        </el-card>
                      </div>
                      <div class="box-one">
                        <el-card class="box-card" style="margin: 20px 0px 30px;">
                          <div slot="header" class="clearfix">
                            <span style="font-size: 18px;font-weight: 600">特殊商品原则</span>
                          </div>
                          <div>
                            <p style="text-indent: 2em;line-height:30px">
                              源码项目一经下单，百度网盘链接自动发货，其具备可复制特性，<strong
                                style="color: #0069d9">暂不支持无理由退款</strong>，如您执意退货，可联系与客服进行联系协商。购买远程服务的客户，在进行远程协助前，均支持<strong
                                style="color: #0069d9">60天无理由退款</strong>。首次远程价格以系统购买价格为准，远程服务为一次性服务，如您后续不小心破坏了项目完整性，导致程序无法正常运行，可支付续杯优惠价（9.9元/次）进行再次远程。
                            </p>
                          </div>
                        </el-card>
                      </div>
                      <div class="box-one">
                        <el-card class="box-card" style="margin: 20px 0px 30px;">
                          <div slot="header" class="clearfix">
                            <span style="font-size: 18px;font-weight: 600">环境安装教程</span>
                          </div>
                          <div>
                            点击前往：<a href="course">基础环境安装与配置教程</a>
                          </div>
                          <div style="margin-top: 10px;color: #8a8f9c">
                            特别说明：环境安装包括JAVA、Python、Mysql、常用开发工具、常用爬虫环境的安装
                          </div>
                        </el-card>
                      </div>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </el-card>

            </div>
          </div>
          <div class="col-xl-3 unique wow fadeInUp" style="padding: 0 !important;">
            <div class="detsils-box">
              <el-card class="product-information-content  wow fadeInUp">
                <div class="shop-info">
                  <img src="@/static/image/logo-ract.svg" style="width: 90px;width: 85px;"
                       alt="">
                  <div style="text-align: left;font-size: 14px;">
                    <p>
                      作者：<span>{{ goodObj.authorNickName }}</span>
                    </p>
                    <p v-if="goodObj.isOriginal">店铺：
                      <el-tag><img src="@/static/image/rz.png" style="width: 16px;margin-bottom: 2px;">已认证</el-tag>
                      是云官方自营店
                    </p>
                    <p v-else>店铺：

                      <el-tag><img src="@/static/image/rz.png" style="width: 16px;margin-bottom: 2px;">已认证</el-tag>
                      是云优选旗舰店
                    </p>

                    <p>
                    <p>信誉：<img src="@/static/image/xy.png" style="width: 22px" v-for="i in [1,2,3,4,5]"></p>

                    <p style="margin-bottom: 5px">
                      发布时间：<span>{{ parseTime(goodObj.publishTime) }}</span>
                    </p>

                  </div>
                  <div>
                    <el-tag type="warning" class="show-tag"><img src="@/static/image/bzj.png"
                                                                 style="width: 14px;margin-bottom: 3px;">已缴保证金
                    </el-tag>
                    <el-tag class="show-tag"><img src="@/static/image/sm.png"
                                                  style="width: 16px;margin-bottom: 2px;">已实名认证
                    </el-tag>
                    <el-tag type="danger" class="show-tag"><img src="@/static/image/sh.png"
                                                                style="width: 16px;margin-bottom: 2px;">官方售后
                    </el-tag>
                    <el-tag type="success" class="show-tag"><img src="@/static/image/db.png"
                                                                 style="width: 16px;margin-bottom: 2px;">官方担保
                    </el-tag>
                    <el-tag type="warning" class="show-tag"><img src="@/static/image/jp.png"
                                                                 style="width: 16px;margin-bottom: 2px;">金牌店铺
                    </el-tag>
                  </div>
                </div>
              </el-card>
            </div>
            <div class="detsils-box flex-warp">
              <el-card v-for="(commodity,index) in recommendGoods"
                       style="margin: 10px 0 0 0;border-radius: 4px !important;" class="themes-box">
                <div slot="header" class="clearfix" v-if="index==0">
                  <span style="font-size: 16px;font-weight: inherit;">推荐项目</span>
                </div>
                <div class="thumb">
                  <el-image style="width: 200pt;height: 120pt"
                            fit="contain"
                            :src="baseUrl+commodity.imgPath" alt="">
                    <div slot="placeholder" class="image-slot">
                      <div style="width: 200pt;height: 120pt;text-align: center;padding-top: 50pt;color: #8a959f">
                        加载中.....
                      </div>
                    </div>
                  </el-image>
                  <div class="button-box">
                    <a href="javascript:void(0)" class="link link-1"
                       @click="toTjDetails(commodity.goodsCode)"><i
                        class="far fa-eye"></i></a>
                    <a href="javascript:void(0)" class="link link-2" @click="buy(commodity.goodsCode)"><i
                        class="fas fa-cart-plus"></i></a>
                  </div>
                </div>
                <div class="hand" @click="toTjDetails(commodity.goodsCode)">
                  <p class="good-name">
                    <span class="tag-good-self" v-if="commodity.isOriginal==1">原创</span>
                    <span class="tag-good-open" v-if="commodity.isOriginal==0">精品</span>
                    {{ commodity.goodsName }}
                  </p>
                  <p class="tag-list">
                    <span class="tec-tag" v-for="(tech,index)  in commodity.techNames">{{ tech }}</span>
                    <!--                      <span class="tec-tag" v-for="(tech,index)  in commodity.techNames" v-if="index<5">{{ index == 5 ? ' ...... ' : tech}}</span>-->
                  </p>
                  <div class="goods-bottom">
                    <!-- 价格-->
                    <span class="goods-price">
                                <h3 class="subtitle">￥{{ numberToFixed(commodity.currentPrice, 2) }}</h3>
                      </span>
                    <span class="goods-time">
<!--                                     {{ parseTime(commodity.publishTime, 'yyyy-MM-dd ') }}-->
                      </span>
                    <span class="goods-pl">
                 <!--                        <p>{{ commodity.commentCount > 0 ? commodity.commentCount + '条评论' : '暂无评论' }}</p>-->
                        <el-rate
                            v-model="commodity.score"
                            disabled
                            show-score
                            text-color="#ff9900"
                            score-template="{value}分">
                        </el-rate>
                      </span>
                  </div>
                </div>
              </el-card>

            </div>
          </div>
        </div>
      </div>
    </section>
    <!--====== 付费项目内容 End  ======-->
    <ImageShow :show.sync="showBig" :currentImage="bigImageUrl" :imageList="imageList"></ImageShow>


  </div>

</template>
<script>
import {getGoodsByCode, getGroupGoods, getRandomGoods} from '../../api/good';
import {getCommentList} from '@/api/comment';
import {getTreeFloor} from "@/utils/shiyuncode";
import "@/assets/css/github-markdown.min.css"
import ImageShow from "@/components/imageShow/index.vue";

export default {
  name: 'ProductDetails',
  components: {ImageShow},
  data() {
    return {
      baseUrl: process.env.VUE_APP_SERVER_URL,
      pageNo: 1,
      pageSize: 10,
      imageList: [], // 图片列表
      showBig: false, // 是否显示大图
      bigImageUrl: '', // 大图地址
      goodsGroup: [],
      dataIdArr: [],
      developFloor: 2,
      isTree: false,
      totalPage: 0,
      m: 1,
      totalCount: 0,
      commentList: [],
      goodObj: {},
      noMsg: '小编正在制作中...',
      rate: 5,
      activeName: 'first',
      webTitle: '1234567',
      goodsCode: "",
      loadNum: 5,
      recommendGoods: [],
      title: "",
      groupServe: [],
      groupServeValue: {},
    };
  },
  created() {
    //初始化项目
    this.goodsCode = this.$route.query.goodsCode;
    if (this.goodsCode) {
      this.loadGoods();
      this.loadRecommendGoods();
      debugger
      this.imageList = this.parseRichText();
    } else {
      this.$router.push('/');
    }
  },
  methods: {
    parseRichText(content) {
      const imgTagRegex = /<img [^>]*src="([^"]+)"[^>]*>/g;
      const imgPaths = [];
      let match;
      while ((match = imgTagRegex.exec(content)) !== null) {
        imgPaths.push(match[1]);
      }
      this.imageList = imgPaths;
    },

    imageEnlargement(e) {
      this.showBig = false;
      if (e.target.nodeName == 'IMG') {//判断点击富文本内容为img图片
        this.showBig = true;
        this.bigImageUrl = e.target.currentSrc;
      }
    }
    ,
    updatePageTitle(title) {
      parent.document.title = title; // 更新页面标题
    },
    buy() {
      this.$router.push({
        path: '/buy',
        query: {goodsCode: this.goodObj.goodsCode, groupServeStr: JSON.stringify(this.groupServeValue)}
      });
    },
    goMain() {
      this.$router.push('/');
    },
    handleClick(tab, event) {
      if (this.activeName == 'fourth') {
        // this.loadComments()
      }
    },
    pageNoHandle(currentPage) {
      this.pageNo = currentPage;
      this.loadComments();
    },
    loadComments() {
      let search = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        search: ' goods_code = \'' + this.goodsCode + '\'',
      };
      getCommentList(search).then(res => {
        this.commentList = res.data.data;
        this.totalPage = res.data.totalPage;
        this.totalCount = res.data.totalCount;

      });
    },


    toDetails() {
      this.loadGoods();
    },
    loadGoods() {
      //加载项目详情
      getGroupGoods(this.goodsCode).then(res => {
        this.goodsGroup = res.data;

      })

      getGoodsByCode(this.goodsCode).then(res => {
        if (res.code == 200) {
          this.goodObj = res.data;
          this.updatePageTitle(this.goodObj.goodsName); // 更新页面标题
          if (this.goodObj.fileTree && this.goodObj.fileTree != null && this.goodObj.fileTree != 'null') {
            this.goodObj.tree = JSON.parse(this.goodObj.fileTree)
            this.dataIdArr = getTreeFloor(this.goodObj.tree, this.developFloor, this.dataIdArr);
            this.isTree = this.dataIdArr.length > 0
          }
          this.groupServe = this.groupByServeCode(this.goodObj.spServeDataDtoList)
          this.parseRichText(res.data.content)
          Object.keys(this.groupServe).forEach(serveCode => {
            this.groupServeValue[serveCode] = ''
          });
        } else {
          this.$modal.msgError(res.msg);
        }
      });
      this.loadComments();
    },
    //点两下取消选中
    clickServe(value, code) {
      if (!value || !code) {
        return
      }
      if (value === this.groupServeValue[code]) {
        this.groupServeValue[code] = null; // 或者 undefined
      } else {
        this.groupServeValue[code] = value;
      }
    },
    changeServe(value, code) {
      this.clickServe(value, code);
      this.$forceUpdate();
    },
    groupByServeCode(data) {
      return data.reduce((groupedData, currentItem) => {
        const serveCode = currentItem.serveCode;
        if (!groupedData[serveCode]) {
          groupedData[serveCode] = [];
        }
        groupedData[serveCode].push(currentItem);
        return groupedData;
      }, {});
    },
    // 加载推荐项目
    loadRecommendGoods() {
      getRandomGoods(this.goodsCode).then(res => {
        this.recommendGoods = res.data;
      });
    }
    , toTjDetails(goodsCode) {
      let routeUrl = this.$router.resolve({
        path: "/details",
        query: {goodsCode: goodsCode}
      });
      window.open(routeUrl.href, '_blank');
    }

  }
};
</script>

<style scoped>

.item-order {
  margin-left: 10px;
  margin-bottom: 10px;
}

.markdown-body {
  padding: 10px 30px;
}

::v-deep .markdown-body ul {
  list-style-type: square !important;
}

.title_desc {
  font-weight: bold;

}

.url_msg {
  font-size: 15px;
}

::v-deep.el-pagination {
  justify-content: center;
}

::v-deep.el-radio {
  margin-right: 0px;
}

::v-deep.el-radio--mini.is-bordered {
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 6px 8px 0 0px !important;
}

::v-deep .el-radio__input {
  display: none !important;

}

.goods-header-img {
  width: 500px;
  float: left;
}

.goods-header-info {
  width: calc(100% - 510px);
  float: left;
  min-height: 210px;
}

.goods-header-buy {
  width: 100%;
  float: right;
  margin-top: 10px;
  text-align: right;
  padding-right: 10px;
}

.st {
  float: left;
  font-size: 12px;
  font-family: simsun;
  color: #999;
}

.sc {
  margin-left: 60px;
}

::v-deep .el-tag {
  height: 22px !important;
  padding: 0 5px !important;
  line-height: 22px !important;
}

.shop-info {
  text-align: center;
}

.shop-info > img {
  margin-bottom: 10px;
}

.shop-info > h4 {
  font-size: 14px;
  margin-top: 5px;
  line-height: 20px;
  color: #8a8f9c;
  width: 100%;
}

.show-tag {
  margin: 10px 5px 0 0;
}

::v-deep .tjsp .el-card__header {
  padding: 10px 20px !important;
}

/* 去除旋转动画 */
::v-deep .el-tree.el-tree-node__expand-icon.expanded {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

/* 有子节点 且未展开 */
::v-deep.el-tree .el-icon-caret-right:before {
  background: url("@/assets/icon/close_folder.png") no-repeat 0 3px;
  content: '';
  content: '';
  display: block;
  width: 20px;
  height: 24px;
  font-size: 20px;
  background-size: 20px;
}

/* //有子节点 且已展开 */
::v-deep .el-tree .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
  background: url("@/assets/icon/open_folder.png") no-repeat 0 3px;
  content: '';
  display: block;
  width: 20px;
  height: 24px;
  font-size: 20px;
  background-size: 20px;
}

/* 没有子节点 */
::v-deep .el-tree .el-tree-node__expand-icon.is-leaf::before {
  background: url("@/assets/icon/code_file.png") no-repeat 0 3px;
  content: '';
  display: block;
  width: 20px;
  height: 24px;
  font-size: 20px;
  background-size: 20px;
}

::v-deep .el-tree-node__content {
  height: 32px !important;
}

::v-deep .el-tree-node__label {
  font-size: 18px;
}

</style>

