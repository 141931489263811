import {login, logout, getInfo, registerLogin} from '@/api/user'
import {getToken, setToken, removeToken} from '@/utils/auth'
import cache from "@/plugins/cache";

const user = {
    state: {
        token: getToken(),
        name: '',
        nickName: '',
        mail: '',
        avatar: '',
        roles: [],
        permissions: []
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_NAME: (state, name) => {
            state.name = name
        },
        SET_MAIL: (state, mail) => {
            state.mail = mail
        },
        SET_NICK_NAME: (state, nickName) => {
            state.nickName = nickName
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
        SET_PERMISSIONS: (state, permissions) => {
            state.permissions = permissions
        }
    },

    actions: {
        // 登录
        Login({commit}, userInfo) {
            const username = userInfo.username.trim();
            const password = userInfo.password;
            const code = userInfo.code;
            const uuid = userInfo.uuid;
            return new Promise((resolve, reject) => {
                login(username, password, code, uuid).then(res => {
                    setToken(res.token);
                    commit('SET_TOKEN', res.token);
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 登录
        RegistLogin({commit}, userInfo) {
            const username = userInfo.username.trim();
            const password = userInfo.password;
            return new Promise((resolve, reject) => {
                registerLogin(username, password).then(res => {
                    setToken(res.token);
                    commit('SET_TOKEN', res.token);
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 获取用户信息
        GetInfo({commit, state}) {
            return new Promise((resolve, reject) => {
                getInfo().then(res => {
                    const user = res.user
                    const avatar = (user.avatar == "" || user.avatar == null) ? require("@/assets/images/profile.png") : "/syshop" + user.avatar;
                    if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
                        commit('SET_ROLES', res.roles)
                        commit('SET_PERMISSIONS', res.permissions)
                    } else {
                        commit('SET_ROLES', ['ROLE_DEFAULT'])
                    }
                    commit('SET_NAME', user.userName)
                    commit('SET_MAIL', user.email)
                    commit('SET_NICK_NAME', user.nickName)
                    commit('SET_AVATAR', avatar)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 退出系统
        LogOut({commit, state}) {
            return new Promise((resolve, reject) => {
                logout(state.token).then(() => {
                    cache.local.remove("store")
                    commit('SET_TOKEN', '')
                    commit('SET_ROLES', [])
                    commit('SET_NICK_NAME', '')
                    commit('SET_MAIL', '')
                    commit('SET_PERMISSIONS', [])
                    removeToken()
                    resolve()
                    location.href = '/';
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 前端 登出
        FedLogOut({commit}) {
            return new Promise(resolve => {
                commit('SET_TOKEN', '')
                removeToken()
                resolve()
            })
        }
    }
}

export default user
