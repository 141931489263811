<template>
  <el-dialog class="imageShow"
             :visible.sync="localVisible"
             :close-on-click-modal="true"
             :close-on-press-escape="true"
             :show-close="true"
             title=""
             width="80vw"
             top="5vh"
  >
    <span class="left-right-btn" style="position: fixed;left: 5vw;top: 40vh;" @click="last"><</span>
    <div class="show-image" style="width: 100%; text-align: center">
      <img :src="currentImage" style="max-width: 100%">
    </div>
    <span class="left-right-btn" style="position: fixed;right: 5vw;top: 40vh;" @click="next">></span>
  </el-dialog>
</template>

<script>
export default {
  name: "ImageShow",
  props: {
    currentImage: String,
    show: Boolean,
    imageList: Array
  },
  data() {
    return {
      localVisible: this.show
    }
  },
  watch: {
    show(newVal) {
      this.localVisible = newVal;
    },
    localVisible(newVal) {
      this.$emit('update:show', newVal);
    }
  }, methods: {
    last() {
      let last_path = this.imageList[this.imageList.length - 1];
      for (let i = 0; i < this.imageList.length; i++) {
        if (this.currentImage.includes(this.imageList[i])) {
          this.currentImage = last_path;
          return;
        }
        last_path = this.imageList[i]
      }
    },
    next() {
      let next_path = this.imageList[0];
      for (let i = this.imageList.length - 1; i >= 0; i--) {
        if (this.currentImage.includes(this.imageList[i])) {
          this.currentImage = next_path;
          return;
        }
        next_path = this.imageList[i]
      }
    }
  }
}
</script>

<style scoped>
::v-deep .el-dialog {
  background-color: rgb(0, 0, 0, 0) !important;
  box-shadow: 0px 0px 0px !important;
}

::v-deep .el-dialog__close {
  background-color: rgb(0, 0, 0, 0.3);
  font-size: 45px;
  border-radius: 25px;
}

::v-deep .el-dialog__headerbtn {
  position: fixed !important;
  right: 5vw;
  top: 5vh;
}

.show-image {
  width: 75vw;
}

::v-deep .el-dialog {
  min-width: auto !important;
}

::v-deep .left-right-btn {
  text-align: center;
  background-color: rgb(0, 0, 0, 0.3);
  font-size: 45px;
  width: 65px;
  color: #909399;
  border-radius: 35px;
  cursor: pointer;
}
::v-deep .left-right-btn:hover {
  color: #409EFF;
}
</style>
