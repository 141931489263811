<template>
  <div>
    <section class="bannner three" style="margin-top: 65px">
      <div class="heroarea container">
        <div class="single item">
          <div class="overlay"></div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-8 text-center">
                <div class="banner-content">
                  <h1 class="head wow fadeInUp" data-wow-delay="0.3s">
                    <!--                    源码分享 &#38; 技术共享-->
                    <span>是云猿实战</span>
                  </h1>
                  <p class="text wow fadeInUp" data-wow-delay="0.4s">
                    欢迎访问是云猿实战，全心全意为你提供可靠的代码服务，赶快注册成为会员吧！在这里你可以获取你所感兴趣的项目源码以及项目开发经验。
                  </p>
                  <div class="text wow fadeInUp" data-wow-delay="0.5s">
                    <div style="width: 80%;margin:0 auto">
                      <el-input placeholder="请输入搜索项目内容关键字" class="search-main"
                                v-model="searchValue"
                                @keyup.enter.native="searchGoods">
                        <template slot="prepend">搜索项目</template>
                        <el-button slot="append" @click="searchGoods"
                                   icon="el-icon-search"></el-button>
                      </el-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--====== Start  ======-->
    <section class="categorry-three three unique">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-lg-6  col-md-6">
            <div class="section-head">
              <h2 class="title">是云实战源码</h2>
              <p class="text">提供优质实战项目和程序设计，满足各种学习使用场景，加速技能提升！</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div class="link">
              <a @click="toAllWith" class="main-btn">查看全部</a>
            </div>
          </div>
        </div>
        <el-tabs v-model="activeName1" @tab-click="handleClick">
          <el-tab-pane v-for="item in categoryList" :label="item.name" :name="item.code" :key="item.code">
            <el-row type="flex" class="flex-warp align-items-center" :gutter="24">
              <el-col v-for="commodity in currentCategoryGoodsList" :sm="12" :md="8" :lg="6" :xl="6"
                      class="text-center">
                <el-card class="themes-box">
                  <div class="thumb">
                    <el-image style="width: 200pt;height: 120pt"
                              fit="contain"
                              :src="baseUrl+commodity.imgPath" alt="">
                      <div slot="placeholder" class="image-slot">
                        <div style="width: 200pt;height: 120pt;text-align: center;padding-top: 50pt;color: #8a959f">
                          加载中.....
                        </div>
                      </div>
                    </el-image>
                    <div class="button-box">
                      <a href="javascript:void(0)" class="link link-1"
                         @click="toDetails(commodity.goodsCode)"><i
                          class="far fa-eye"></i></a>
                      <a href="javascript:void(0)" class="link link-2" @click="buy(commodity.goodsCode)"><i
                          class="fas fa-cart-plus"></i></a>
                    </div>
                  </div>
                  <div class="hand" @click="toDetails(commodity.goodsCode)">
                    <p class="good-name">
                      <span class="tag-good-self" v-if="commodity.isOriginal==1">原创</span>
                      <span class="tag-good-open" v-if="commodity.isOriginal==0">精品</span>
                      {{ commodity.goodsName }}
                    </p>
                    <p class="tag-list">
                      <span class="tec-tag" v-for="(tech,index)  in commodity.techNames">{{ tech }}</span>
                      <!--                      <span class="tec-tag" v-for="(tech,index)  in commodity.techNames" v-if="index<5">{{ index == 5 ? ' ...... ' : tech}}</span>-->
                    </p>
                    <div class="goods-bottom">
                      <!-- 价格-->
                      <span class="goods-price">
                                <h3 class="subtitle">￥{{ numberToFixed(commodity.currentPrice, 2) }}</h3>
                      </span>
                      <span class="goods-time">
<!--                                     {{ parseTime(commodity.publishTime, 'yyyy-MM-dd ') }}-->
                      </span>
                      <span class="goods-pl">
                 <!--                        <p>{{ commodity.commentCount > 0 ? commodity.commentCount + '条评论' : '暂无评论' }}</p>-->
                        <el-rate
                            v-model="commodity.commentCount"
                            disabled
                            show-score
                            text-color="#ff9900"
                            score-template="{value}分">
                        </el-rate>
                      </span>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </div>
    </section>
    <!--======  End  ======-->

    <!--======  Start  ======-->
    <section class="unique themes three">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-lg-6  col-md-6">
            <div class="section-head">
              <h2 class="title">推荐实战源码</h2>
              <p class="text">汇聚最新原创开发项目、最热门话题项目、最完善宝藏项目！</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div class="link">
              <a @click="toAllRecord" class="main-btn">查看全部</a>
            </div>
          </div>
        </div>
        <el-row type="flex" class="flex-warp align-items-center" :gutter="24">
          <el-col v-for="commodity in recommendGoods" :sm="12" :md="8" :lg="6" :xl="6" class="text-center">
            <el-card class="themes-box">
              <div class="thumb">
                <el-image style="width: 200pt;height: 120pt"
                          fit="contain"
                          :src="baseUrl+commodity.imgPath" alt="">
                  <div slot="placeholder" class="image-slot">
                    <div style="width: 200pt;height: 120pt;text-align: center;padding-top: 50pt;color: #8a959f">
                      加载中.....
                    </div>
                  </div>
                </el-image>
                <div class="button-box">
                  <a href="javascript:void(0)" class="link link-1"
                     @click="toDetails(commodity.goodsCode)"><i
                      class="far fa-eye"></i></a>
                  <a href="javascript:void(0)" class="link link-2" @click="buy(commodity.goodsCode)"><i
                      class="fas fa-cart-plus"></i></a>
                </div>
              </div>
              <div @click="toDetails(commodity.goodsCode)" class="hand">
                <p class="good-name">
                  <span class="tag-good-self" v-if="commodity.isOriginal==1">原创</span>
                  <span class="tag-good-open" v-if="commodity.isOriginal==0">精品</span>
                  {{ commodity.goodsName }}
                </p>
                <div class="tag-list">
                  <span class="tec-tag" v-for="tech in commodity.techNames">{{ tech }}</span>
                </div>
                <div class="goods-bottom">
                  <!-- 价格-->
                  <span class="goods-price">
                                <h3 class="subtitle">￥{{ numberToFixed(commodity.currentPrice, 2) }}</h3>
                      </span>
                  <span class="goods-time">
                        <!--                        {{ parseTime(commodity.publishTime, 'yyyy-MM-dd ') }}-->
                      </span>
                  <span class="goods-pl">
<!--                        <p>{{ commodity.commentCount > 0 ? commodity.commentCount + '条评论' : '暂无评论' }}</p>-->
                           <el-rate
                               v-model="commodity.commentCount"
                               disabled
                               show-score
                               text-color="#ff9900"
                               score-template="{value}分">
                        </el-rate>
                  </span>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </section>
    <!--====== End  ======-->
  </div>
</template>

<script>
import {getCategory, getGoods} from '@/api/good';

export default {
  name: 'HomeView',
  data() {
    return {
      baseUrl: process.env.VUE_APP_SERVER_URL,
      activeName1: '',
      categoryList: [],
      searchValue: '',
      currentCategoryGoodsList: [],
      recommendGoods: []
    };
  },
  mounted() {
    //加载分类
    getCategory().then(res => {
      if (res.code == 200) {
        this.categoryList = Object.freeze(res.data);
        this.activeName1 = res.data[0].code;
        //查询第一个类目下的商品
        this.loadGoodsByCategoryCode(res.data[0].code);
      }
    });
    //查询推荐商品
    this.loadRecommendGoods();
  },
  methods: {
    //加载不同类型的项目
    loadGoodsByCategoryCode(code) {
      let search = {
        pageNo: 1,
        pageSize: 8,
        search: code == 'all' ? '1 = 1' : 'category_code like "%' + code + '%"'
      };
      getGoods(search).then(res => {
        this.currentCategoryGoodsList = res.data.data;
      });
    },
    // 加载推荐项目
    loadRecommendGoods() {
      let search = {
        pageNo: 1,
        pageSize: 8,
        search: 'is_top=1'
      };
      getGoods(search).then(res => {
        this.recommendGoods = res.data.data;
      });
    },
    handleClick(tab, event) {
      this.loadGoodsByCategoryCode(tab.name);
    },
    toDetails(goodsCode) {
      let routeUrl = this.$router.resolve({
        path: '/details',
        query: {goodsCode: goodsCode}
      });
      window.open(routeUrl.href, '_blank');
    },
    toAllWith() {
      this.$router.push({name: 'productlist', params: {code: this.activeName1}});
    },
    toAllRecord() {
      this.$router.push({name: 'productlist', params: {code: '1'}});
    },
    buy(goodsCode) {
      let routeUrl = this.$router.resolve({
        path: '/buy',
        query: {goodsCode: goodsCode}
      });
      window.open(routeUrl.href, '_blank');
    }, searchGoods() {
      this.$router.push({name: 'productlist', query: {search: this.searchValue}});
    }
    ,
  }
}
;
</script>

<style scoped>
p {
  margin-bottom: 0px
}

.flex-warp {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.flex-warp {
  flex-wrap: wrap;
  flex-direction: row
}


.heroarea .single {
  background: url("~@/static/image/banner3-1.png");
}

.unique {
  padding: 50px 0px 50px;
}

.bannner.three .heroarea .single {
  padding: 150px 0px 150px;
  position: relative;
  z-index: 1;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}


/deep/ .el-card__body, .el-main {
  padding: 10px;
}

.link:hover {
  cursor: pointer;
}

/deep/ div.search-main > input {
  padding: 3px 10px !important;
  height: 60px;
  border: 1px solid #ccc !important;
}

/deep/ div.search-main {
  font-size: 16px;
}


</style>
