<template>
  <div>
    <!--====== Banner Start ======-->
    <section class="breadcrumb-area">
      <div class="container">
        <div class="content">
          <h2 class="breadd  wow fadeInUp" id="title-1">
            立即购买项目-获取源码
          </h2>
          <ul class="breadcrumb-list  wow fadeInUp">
            <li><a>首页 /</a></li>
            <li>立即购买</li>
          </ul>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="row">

        </div>
      </div>
    </section>

    <section>
      <div class="container wow fadeInUp">
        <div class="row" style="padding-top: 30px;margin-bottom: 20px;">
          <div class="col-xl-8  col-lg-12" style="margin-bottom: 10px">
            <el-card class="wow fadeInUp" style="padding-bottom: 20px;">
              <h6 class="subtitle text-left order-title">确认商品信息</h6>
              <div class="goods-header-img">
                <div style="width: 300px;height: 166px;background-color: #eeeeee;text-align: center">
                  <el-image v-for="(imgUrl,index) in [goodsForm.imgPath]"
                            style="height: 166px;width: auto;max-width:300px;margin: 0px auto;"
                            class="comment-content-img"
                            :initial-index="index"
                            :src="baseUrl+imgUrl"
                            :preview-src-list="[baseUrl+goodsForm.imgPath]">
                  </el-image>
                </div>
              </div>
              <div class="goods-header-info">
                <div>
                  <h5 style="line-height: 34px">
                    【{{ goodsForm.goodsCode }}】{{ goodsForm.goodsName }}</h5>
                </div>
                <div style="padding-left: 10px">
                  <el-tag :key="tag" v-for="tag in goodsForm.techNames"
                          style="margin-right: 5px">
                    {{ tag }}
                  </el-tag>
                </div>
                <div style="padding-left: 10px;margin-top: 8px;">
                  <div class="st">源码售价：</div>
                  <span class="subtitle">￥{{ numberToFixed(goodsForm.currentPrice, 2) }}</span>
                  <span
                      style="font-size: 16px;text-decoration: line-through;margin-left: 5px;color: #8a8f9c;">￥{{
                      numberToFixed(goodsForm.originalPrice, 2)
                    }}</span>
                </div>
                <div style="padding-left: 10px;margin-top: 8px;" v-for="(items, serveCode) in groupServe"
                     :key="serveCode">
                  <div class="st">{{ items[0].serveTypeName }}：</div>
                  <div class="sc">
                    <el-radio-group v-model="groupServeValue[serveCode]" size="mini">
                      <el-tooltip placement="top" effect="light" :disabled="!item.remark" v-for="item in items">
                        <div slot="content" style="width: 160px">{{ item.remark }}</div>
                        <el-radio :label="item.code" :key="item.code"
                                  @click.native.stop.prevent="changeServe(item.code,serveCode)" border>
                          {{ item.name }}<span> ￥{{
                            item.price
                          }}</span>
                        </el-radio>
                      </el-tooltip>
                    </el-radio-group>
                  </div>
                </div>
              </div>
            </el-card>
            <el-card class="wow fadeInUp" style="margin-top: 10px">
              <div class="tab-content" id="pills-tabContent">
                <h6 class="text-left order-title">确认订单信息</h6>
                <div class="row">
                  <el-form :model="orderForm" :rules="rules" ref="orderForm" label-width="80px">
                    <div class="row justify-content-lg-end">
                      <div class="col-lg-12">
                        <div class="bottom-box text-right">
                          <el-form-item label="邮箱" prop="userMail">
                            <el-input v-model="orderForm.userMail" placeholder="请输入接收邮箱（必填）"></el-input>
                          </el-form-item>

                          <el-form-item label="备注" prop="remark">
                            <el-input v-model="orderForm.remark"
                                      placeholder="如购买服务，请填写微信号备注"></el-input>
                          </el-form-item>

                        </div>
                      </div>
                    </div>
                  </el-form>
                  <div style="width: 100%;">
                    <div style="width: 155px;text-align: left;float: right">
                      <div>
                        <span class="order-price-title">增值服务：</span>
                        <span class="order-price">￥{{ numberToFixed(servicePrice, 2) }}</span>
                      </div>
                      <div>
                        <span class="order-price-title">源码价格：</span>
                        <span class="order-price">￥{{ numberToFixed(goodsForm.currentPrice, 2) }}</span>
                      </div>
                      <div>
                        <span class="order-price-title">实付总额：</span>
                        <span class="order-price-total">￥{{
                            numberToFixed(goodsForm.currentPrice + Number(servicePrice), 2)
                          }}</span>
                      </div>
                    </div>
                  </div>
                  <div style="width: 100%;text-align: right;padding-right: 55px">
                    <el-button type="danger" @click="payOrder" id="pay" :disabled="toPayFlag"
                               style="margin-top: 5px;">立即购买
                    </el-button>
                  </div>
                </div>
              </div>
            </el-card>
          </div>
          <div class="col-xl-4 col-lg-12" style="">
            <div class="box-one">
              <el-card class="box-card" style="">
                <div slot="header" class="clearfix">
                  <span class="desc-head">安装服务说明</span>
                </div>
                <div class="desc-text-mark">
                  购买调试服务，备注请填写微信联系方式
                </div>
                <div class="desc-text">
                  <strong>不需要远程服务</strong>：下单购买仅项目源码，不含远程调试。如无法调试，也可单独联系客服微信购买远程服务。
                </div>
                <div class="desc-text">
                  <strong>有环境远程服务</strong>：不包含开发环境安装，只协助远程调试成功运行项目。
                </div>
                <div class="desc-text">
                  <strong>无环境远程服务</strong>：环境安装包括JAVA、Python、Mysql、常用开发工具、常用爬虫驱动等项目所需要的开发环境安装，以及协助远程调试成功运行项目。
                </div>
              </el-card>
              <el-card class="box-card" style="margin-top: 11px">
                <div slot="header" class="clearfix">
                  <span class="desc-head">售后服务说明</span>
                </div>
                <div class="desc-text">
                  <strong>远程服务</strong>：订单未购买远程，出现调试问题，可单独联系客服进行购买远程调试服务。
                </div>
                <div class="desc-text">
                  <strong>咨询售后</strong>：提供本项目的相关技术、业务、毕设、课设问题解答与建议。
                </div>
                <div class="desc-text">
                  <strong>付费定制</strong>：通用项目不具备唯一性，或许不能满足全部用户需求，提供定制化功能开发服务。
                </div>


              </el-card>
            </div>
          </div>
        </div>
      </div>
    </section>


    <div ref="alipayWap" v-html="alipay"/>
  </div>

</template>

<script>
import {getGoodsByCode} from '@/api/good';
import {postAlipay} from '@/api/order';
import store from '@/store'

export default {
  name: 'Buy',
  created() {
    this.initForm();
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_SERVER_URL,
      goodsForm: {},
      rate: 5,
      noMsg: '小编正在制作中...',
      orderForm: {
        goodsCode: '',
        goodsName: '',
        userMail: '',
        orderPrice: '',
        serveInfo: '',
        remark: ''
      },
      rules: {
        userMail: [
          {type: 'email', required: true, message: '请输入正确的邮箱', trigger: 'blur'},
        ],
        remark: [{
          required: false,
          message: '请输入微信联系方式',
          trigger: 'blur'
        }],
      },
      alipay: '',
      toPayFlag: true,
      isLogin: false,
      servicePrice: 0,
      groupServe: [],
      service: [],
      groupServeValue: {},
    }
  },
  methods: {
    initForm() {
      let goodsCode = this.$route.query.goodsCode;
      let groupServeStr = this.$route.query.groupServeStr;

      if (goodsCode == undefined) {
        this.$modal.msgError('非法参数，无法加载下单页面')
      } else {
        getGoodsByCode(goodsCode).then(res => {
          if (res.data) {
            this.goodsForm = res.data;
            this.orderForm.id = res.data.id;
            this.orderForm.goodsCode = res.data.goodsCode;
            this.orderForm.goodsName = res.data.goodsName;
            this.orderForm.userMail = store.getters.mail;
            this.isLogin = this.orderForm.userMail != undefined && this.orderForm.userMail != '';
            this.orderForm.orderPrice = res.data.currentPrice;
            this.orderForm.remark = res.data.remark;
            this.service = res.data.spServeDataDtoList;
            this.groupServe = this.groupByServeCode(res.data.spServeDataDtoList)
            if (!groupServeStr) {
              Object.keys(this.groupServe).forEach(serveCode => {
                this.groupServeValue[serveCode] = ''
              });
            } else {
              this.groupServeValue = JSON.parse(groupServeStr);
              this.changeServe()
            }
            this.toPayFlag = false
          } else {
            this.$modal.msgError('非法参数，无法加载下单页面')
          }
        });
      }

    },
    //点两下取消选中
    clickServe(value, code) {
      if (!value || !code) {
        return
      }
      if (value === this.groupServeValue[code]) {
        this.groupServeValue[code] = null; // 或者 undefined
      } else {
        this.groupServeValue[code] = value;
      }
    },
    changeServe(value, code) {
      this.clickServe(value, code)
      this.$forceUpdate();
      this.servicePrice = 0
      Object.keys(this.groupServeValue).forEach(tagCode => {
        let code = this.groupServeValue[tagCode]
        let service = this.service.find(i => i.code === code && i.serveCode === tagCode);
        let price = service ? service.price : 0;
        this.servicePrice += price
      });
      this.handleServer()
    },
    groupByServeCode(data) {
      return data.reduce((groupedData, currentItem) => {
        const serveCode = currentItem.serveCode;
        if (!groupedData[serveCode]) {
          groupedData[serveCode] = [];
        }
        groupedData[serveCode].push(currentItem);
        return groupedData;
      }, {});
    },
    payOrder() {

      /**
       * 购买后会跳转到下载链接页面，同时也会通过邮箱发送下载内容；还可以通过订单号（O开头）进行查询下载链接；如已登录也可进入个人中心进行下载！
       */
      this.$refs.orderForm.validate((valid) => {
        if (valid) {
          this.orderForm.orderPrice = Number(this.goodsForm.currentPrice) + Number(this.servicePrice);
          this.orderForm.codePrice = Number(this.goodsForm.currentPrice);
          this.orderForm.servicePrice = Number(this.servicePrice);
          let serveInfoList = []
          Object.keys(this.groupServeValue).forEach(tagCode => {
            let code = this.groupServeValue[tagCode]
            let service = this.service.find(i => i.code === code && i.serveCode === tagCode);

            if (service) {
              serveInfoList.push(service)
            }
          });

          if(serveInfoList.length>0){
            this.orderForm.serveInfo = JSON.stringify(serveInfoList)
          }else{
            this.orderForm.serveInfo = ""
          }
          this.toPayFlag = true;
          postAlipay(this.orderForm).then(res => {
            this.alipay = res.payForm;
            // 防抖避免重复支付
            this.$nextTick(() => {
              // 提交支付表单
              this.$refs.alipayWap.children[0].submit();
              setTimeout(() => {
                this.toPayFlag = false;
              }, 500);
            })
          })
        }
      })
    }, handleServer() {
      this.rules.remark = [{
        required: this.servicePrice > 0,
        message: '请输入微信联系方式',
        trigger: 'blur'
      }];
    }
  }
}
</script>

<style scoped>


.product-details .detsils-box .box-one .total-link {
  display: block;
}

.item-order {
  margin-left: 10px;
  margin-bottom: 10px;
}

.title_desc {
  font-weight: bold;

}

.url_msg {
  font-size: 15px;
}

::v-deep.el-radio {
  margin-right: 0px;
}

::v-deep.el-radio--mini.is-bordered {
  margin-left: 10px !important;
  margin-bottom: 10px;
  padding: 6px 8px 0 0px !important;
}

::v-deep .el-radio__input {
  display: none !important;

}

.st {
  float: left;
  font-size: 12px;
  font-family: simsun;
  margin-left: 10px;
  color: #999;
}

.sc {
  margin-left: 60px;
}

::v-deep .el-tag {
  height: 22px !important;
  padding: 0 5px !important;
  line-height: 22px !important;
}

.goods-header-img {
  width: 310px;
  float: left;
}

.goods-header-info {
  width: calc(100% - 320px);
  float: left;
  min-height: 126px;
}

.order-price-title {
  font-size: 12px;
  color: #999;
  height: 20px;
}

.order-price {
  color: #8a8f9c;
  font-size: 16px;
}

.order-price-total {
  font-size: 12px;
  color: #999;
}

.order-price-total {
  color: #ff5000;
  font-size: 20px;
}

.desc-text {
  margin-bottom: 7pt;
  font-size: 14px;
  color: #5e5e5e;
}

.desc-text-mark {
  margin-bottom: 7pt;
  color: #ff4a4a;
  font-size: 14px;
  font-weight: bold;
}

.desc-head {
  font-size: 18px;
  font-weight: bolder;
  color: #5e5e5e;
}

.order-title {
  margin-bottom: 17px;
  font-weight: bold;
}
</style>
