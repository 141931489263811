<template>
  <div>
    <!--====== 付费项目内容 Start  ======-->
    <section class="product-details">
      <div class="container">
        <div class="row">
          <div class="col-xl-4">
            <div class="detsils-box">
              <el-card class="box-one">
                <div class=" wow fadeInUp text-center">
                  <img
                      style="width: 300px;height: 200px;"
                      :src="baseUrl+orderData.imgPath"/>
                </div>
                <div class="total-link wow fadeInUp" style="margin-top: 18px">
                  <p style="font-size: 13px"> 项目编号：{{ orderData.goodsCode }}</p>
                  <p style="font-size: 13px"> 订单编号：{{ orderData.orderCode }}</p>
                  <p style="font-size: 13px"> 项目名称：{{ orderData.goodsName }}</p>
                  <p style="font-size: 13px"> 下单时间：<span id="publishTime">{{
                      parseTime(orderData.createTime)
                    }}</span></p>
                  <p style="font-size: 13px"> 付款时间：<span id="publishTime">{{
                      parseTime(orderData.payTime)
                    }}</span></p>
                </div>
              </el-card>
            </div>
          </div>
          <div class="col-xl-8 col-lg-12">
            <div class="box-one">
              <el-card class="product-information-content   wow fadeInUp">
                <div class="pi-content">
                  <el-input
                      type="textarea"
                      :disabled="isComment"
                      :rows="2"
                      placeholder="请输入内容"
                      v-model="content">
                  </el-input>
                </div>
                <div class="price-rating">
                  <el-upload
                      accept="image/*"
                      action="#"
                      :class="{'hiddenUp' :isMax,'fixInline' :isFix}"
                      :disabled="isComment"
                      list-type="picture-card"
                      :on-change="handleChange"
                      ref="upload"
                      :auto-upload="false">
                    <i slot="default" class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{file}">
                      <img
                          class="el-upload-list__item-thumbnail"
                          :src="file.url" alt="">
                      <span class="el-upload-list__item-actions">
                          <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span v-if="!isComment" class="el-upload-list__item-delete" @click="handleRemove(file)">
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                    </div>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="">
                  </el-dialog>
                  <div :class="{'rating':true,'fixRating':!isFix}">
                    <span>得分：</span>
                    <span class="i-box"><el-rate :disabled="isComment" v-model="score" allow-half></el-rate> </span>
                  </div>
                </div>
                <div :class="{'button-2':true,'fixHeight':isFix}">
                  <a>
                    <button class="main-btn one" @click="goMain">
                      返回主页
                    </button>
                  </a>
                  <a>
                    <el-button v-if="!isComment" class="main-btn" :disabled="orderForm.orderCode" @click="handleSubmit">
                      确定评论
                    </el-button>
                  </a>
                </div>
              </el-card>
            </div>
          </div>

        </div>
      </div>
    </section>
    <!--====== 付费项目内容 End  ======-->
  </div>
</template>


<script>
import {getOrderByCode, uploadsComment} from '../../api/order';
import {pushComment} from "@/api/comment";

export default {
  name: 'ProductDetails',
  data() {
    return {
      baseUrl: process.env.VUE_APP_SERVER_URL,
      orderData: {},
      noMsg: '小编正在制作中...',
      limit: 3,
      content: "",
      isHidden: 1,
      score: 0,
      orderForm: {},
      dialogImageUrl: '',
      dialogVisible: false,
      isFix: true,//修复初始化高度跳动
      isMax: false,
      isComment: false//是否已经评论
    };
  },
  methods: {
    goMain() {
      this.$router.push('/');
    },
    handleRemove(file) {
      let uploadFiles = this.$refs.upload.uploadFiles;
      this.isFix = uploadFiles.length == 1;
      for (let i = 0; i < uploadFiles.length; i++) {
        if (uploadFiles[i]['url'] == file.url) {
          uploadFiles.splice(i, 1)
        }
      }
      this.isMax = false;

    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleChange(file, fileList) {
      this.isFix = fileList.length == 0;
      this.isMax = fileList.length >= this.limit;
    },
    handleSubmit() {
      if (this.isComment) {//如果已经评论则不操作
        return;
      }
      let uploadFiles = this.$refs.upload.uploadFiles;
      let fileList = new FormData()
      uploadFiles.forEach(file => {
        fileList.append('files', file.raw)
      })

      uploadsComment(fileList).then(res => {//上传图片
        if (res.code == 200) {
          let commentData = new FormData();
          let fileNames = res.fileNames;
          if (fileNames) {//评论图片
            commentData.append("imgInfo", fileNames)
          }
          commentData.append("goodsCode", this.orderData.goodsCode)
          commentData.append("orderCode", this.orderData.orderCode)
          commentData.append("content", this.content)
          commentData.append("score", this.score)
          commentData.append("isHidden", this.isHidden)
          pushComment(commentData).then(obj => {
            if (obj.code == 200) {
              this.$modal.msgSuccess("评论成功！")
              this.isComment = true;
            } else {
              this.$modal.msgError(obj.msg)
            }
          })
        } else {
          this.$modal.msgError("系统开小差了，请刷新后重试")
        }
      });

    }
  },
  mounted() {
    let orderCode = this.$route.query.orderCode;
    getOrderByCode(orderCode).then(res => {
      if (res.code == 200) {
        this.orderData = res.data;
        if (res.data && res.data.commentStatus === 0) {
          this.orderData = res.data;
        } else if (res.data.commentStatus === 1) {
          this.$alert('当前订单已完成评价', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
              this.$router.push({path: '/details', query: {goodsCode: res.data.goodsCode}});
            }
          })
        } else {
          this.$alert('您暂无权限操作评价', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
              this.$router.push({path: '/'});
            }
          })
        }
      } else {
        this.$modal.msgError(res.msg);
      }
    });

  }
};
</script>

<style scoped lang="less">
.product-details .detsils-box .box-one .total-link {
  display: block;
}

/deep/ .hiddenUp .el-upload {
  display: none !important;
}

/deep/ .fixHeight {
  margin-top: 14px;
}

/deep/ .fixRating {
  margin-bottom: 14px;
}

/deep/ .fixInline .el-upload-list {
  display: none;
}

.item-order {
  margin-left: 10px;
  margin-bottom: 10px;
}

.title_desc {
  font-weight: bold;

}

.url_msg {
  font-size: 15px;
}

.product-details {
  background-color: #fff !important;
}

.product-details .detsils-box .box-one {
  max-height: 479px;
}
</style>
