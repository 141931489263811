<template>
  <div>
    <header :class='{"header-three":true,"sticky-header":true,"footset":false }'>
      <ul id="nav" class="header-navigation">
        <li>
          <a href="javascript:void(0)"><img src="@/static/picture/logo-2.svg" alt="amcloud"
                                            style="height: 50px;padding-left: 30px;">
            <i class="fas fa-stream" style="float: right;margin-top: 16px;padding-right: 30px;color: #C0C0C0"></i></a>
          <ul>
            <li class="sm-li">
              <!--                <a class="nav-link" href="/">首页</a>-->
              <router-link class="nav-link" to="/">首页</router-link>
            </li>

            <li class="sm-li">
              <router-link class="nav-link" to="/productList">实战源码</router-link>
            </li>
<!--            <li class="sm-li">-->
<!--              <router-link class="nav-link" to="/graduation">毕设一对一</router-link>-->
<!--            </li>-->
            <li class="sm-li">
              <router-link class="nav-link" to="/help">帮助中心</router-link>
            </li>
            <li class="sm-li">
              <router-link class="nav-link" to="/course">环境安装</router-link>
            </li>
            <li class="sm-li" v-if="nickName">
              <router-link class="nav-link" to="/my">个人中心</router-link>
            </li>
            <li class="sm-li" v-if="nickName">
              <a class="nav-link" @click="loginOut()" href="javascript:void(0)">注销</a>
            </li>
            <li class="sm-li" v-if="!nickName">
              <a class="nav-link" @click="toRegister()" href="javascript:void(0)">注册</a>
            </li>
            <li class="sm-li" v-if="!nickName">
              <a class="nav-link" @click="toLogin()" href="javascript:void(0)">登录</a>
            </li>

          </ul>
        </li>
      </ul>

      <div class="header-navigation">
        <div class="container-fluid d-flex align-items-center justify-content-between">
          <div class="header-left">
            <ul class="primary-menu">
              <li class="site-logo">
                <a href="/"><img src="@/static/picture/logo-2.svg" alt="amcloud" style="height: 50px"></a>
              </li>
              <li class="current">
                <!--                <a class="nav-link" href="/">首页</a>-->
                <router-link class="nav-link" to="/">首页</router-link>
              </li>

              <li>
                <router-link class="nav-link" to="/productList">实战源码</router-link>
              </li>

              <!--              <li class="hidden-xs-only">-->
              <!--                <router-link class="nav-link" to="/graduation">毕设一对一</router-link>-->
              <!--              </li>-->
              <li class="hidden-xl-only">
                <router-link class="nav-link" to="/help">帮助中心</router-link>
              </li>
              <li class="hidden-xs-only">
                <router-link class="nav-link" to="/course">环境安装</router-link>
              </li>
              <li>
                <a href="javascript:void(0)" @click="showOrderSearch">订单查询</a>
              </li>
            </ul>
          </div>
          <div class="header-center">
            <!--            <el-input placeholder="请输入内容" class="input-with-select search-main" v-model="searchValue"-->
            <!--                      v-if="!searchHidden"-->
            <!--                      @keyup.enter.native="searchGoods">-->
            <!--              <el-button slot="append" @click="searchGoods" icon="el-icon-search"></el-button>-->
            <!--            </el-input>-->
          </div>
          <div class="header-right justify-content-end">
            <div v-if="!nickName" class="header-extra d-flex">
              <div class="navbar-btn" style="">
                <el-button type="primary" @click="toLogin()"><i class="fas fa-sign-in"></i>&nbsp;登录
                </el-button>
              </div>
              <div class="navbar-btn" style="margin-left: 20px;">
                <el-button type="primary" @click="toRegister()"><i
                    class="el-icon-position"></i>&nbsp;注册
                </el-button>
              </div>

            </div>
            <div v-else class="header-extra d-flex">
              <div class="nav-link" style="padding-top: 10px;">
                <router-link to="/my" class="change-menu"><i class="fas fa-user"></i><span>&nbsp;<span
                    class="hidden-username">{{ nickName }}</span></span>
                </router-link>
              </div>
              <div class="navbar-btn" style="margin-left: 20px;">
                <el-button type="primary" @click="loginOut()"><i
                    class="fas fa-sign-out"></i>&nbsp;注销
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!--    登录    -->
    <el-dialog
        :close-on-click-modal="false"
        title="登录"
        :visible.sync="loginVisible"
        width="25%"
        :before-close="handleClose">
      <section id="main">
        <h1></h1>
        <el-form :model="userForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
                 @keyup.enter.native.prevent="handleLogin()"
                 class="demo-ruleForm">
          <el-form-item label="账号" prop="username">
            <el-input style="width: 85%;" type="text" placeholder="请输入账号" v-model="userForm.username"
                      autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input style="width: 85%;" type="password" placeholder="请输入密码" v-model="userForm.password"
                      autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <div style="width: 85%;">
              <el-input type="text" v-model="userForm.code" style="width: 140px;margin-right: 10px"
                        placeholder="请输入计算结果" autocomplete="off"></el-input>
              <img @click="loadCaptImg" :src="'data:image/gif;base64,'+captImgUrl"
                   style="height: 40px;">
            </div>
          </el-form-item>
          <div style="width: 87%;">
            <el-checkbox v-model="userForm.rememberMe" style="margin:0px 0px 25px 50px;">记住密码</el-checkbox>
            <a style="float: right;color: #606266" href="#" @click="forgetPass">忘记密码?</a>
          </div>
          <div style="width: 100%;text-align: center">
            <el-button
                :loading="loading"
                size="medium"
                type="primary"
                style="width:100px;"
                @click.native.prevent="handleLogin"
                @keyup.enter.prevent="handleLogin"
            >
              <span v-if="!loading">登 录</span>
              <span v-else>登录中...</span>
            </el-button>
          </div>
        </el-form>
      </section>

    </el-dialog>
    <!--    找回密码    -->
    <el-dialog
        :close-on-click-modal="false"
        title="找回密码"
        :visible.sync="forgetVisible"
        width="25%"
        :before-close="handleClose">
      <section>
        <h1></h1>
        <el-form :model="userForm" status-icon :rules="forgetRules" ref="forgetForm" label-width="100px"
                 class="demo-ruleForm">
          <el-form-item label="账号" prop="username">
            <el-input style="width: 85%;" type="text" placeholder="请输入账号" v-model="userForm.username"
                      autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="mail">
            <el-input style="width: 85%;" type="email" placeholder="请输入邮箱" v-model="userForm.mail"
                      autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="邮箱验证码" prop="verCode">
            <el-input style="width: 60%;" type="text" placeholder="请输入邮箱验证码" v-model="userForm.verCode"
                      autocomplete="off"></el-input>
            <el-button type="success" style="margin-left: 10px" :disabled="isSend" class="checkBtn"
                       @click="sendCheckCode('userForm')">{{ sendMsg }}
            </el-button>
          </el-form-item>
          <el-form-item label="新密码" prop="password">
            <el-input style="width: 85%;" type="password" placeholder="请输入新密码" v-model="userForm.password"
                      autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="再次输入" prop="password">
            <el-input style="width: 85%;" type="password" placeholder="请再次输入新密码"
                      v-model="userForm.repassword"
                      autocomplete="off"></el-input>
          </el-form-item>
          <div style="width: 100%;text-align: center">
            <el-button
                :loading="loading"
                size="medium"
                type="primary"
                style="width:100px;"
                @click.native.prevent="handleForget"
            >
              <span v-if="!loading">确 定</span>
              <span v-else>提交中...</span>
            </el-button>
          </div>
        </el-form>
      </section>
    </el-dialog>
    <!--    查询订单    -->
    <el-dialog
        :close-on-click-modal="false"
        title="订单查询"
        :visible.sync="orderVisible"
        width="25%"
        :before-close="handleClose">
      <section>
        <h1></h1>
        <el-form :model="orderForm" status-icon :rules="orderRules" ref="orderForm" label-width="130px"
                 class="demo-ruleForm">
          <el-form-item label="商家订单号" prop="orderCode">
            <el-input style="width: 85%;" type="text" placeholder="请输入订单号（O开头）" v-model="orderForm.orderCode"
                      autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="下单邮箱" prop="mail">
            <el-input style="width: 85%;" type="email" placeholder="请输入邮箱(下单时填写的邮箱)"
                      v-model="orderForm.mail"
                      autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="邮箱验证码" prop="verCode">
            <el-input style="width: 51%;" type="text" placeholder="请输入邮箱验证码" v-model="orderForm.verCode"
                      autocomplete="off"></el-input>
            <el-button type="success" style="margin-left: 10px" :disabled="isSend" class="checkBtn"
                       @click="sendCheckCode('orderForm')">{{ sendMsg }}
            </el-button>
          </el-form-item>


          <div style="width: 100%;text-align: center">
            <el-button
                :loading="loading"
                size="medium"
                type="primary"
                style="width:100px;"
                @click.native.prevent="handleOrder"
            >
              <span v-if="!loading">查 询</span>
              <span v-else>查询中...</span>
            </el-button>
          </div>
        </el-form>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import {getCaptImg, sendEmailCode, forgetPass} from '../../api/user';
import {findOrder} from '../../api/order';
import Cookies from 'js-cookie';
import {encrypt, decrypt} from '@/utils/jsencrypt'
import store from '@/store'


export default {
  name: 'Header',
  data() {
    return {
      isSend: false,
      sendMsg: '发送验证码',
      loading: false,
      loginVisible: false,
      orderVisible: false,
      showHeaderBg: false,
      searchHidden: false,
      forgetVisible: false,
      orderForm: {
        mail: '',
        verCode: '',
        orderCode: ''
      },
      userForm: {
        username: '',
        password: '',
        rememberMe: false,
        code: '',
        uuid: '',
        verCode: '',
        repassword: '',
        mail: ''
      },
      nickName: store.getters.nickName,
      captImgUrl: '',
      searchValue: '',
      orderRules: {
        mail: [
          {type: 'email', required: true, message: '请输入邮箱', trigger: 'blur'},
        ],
        verCode: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
        ],
        orderCode: [
          {required: true, message: '请输入订单号', trigger: 'blur'},
        ]
      },
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
        code: [
          {required: true, message: '请输入计算结果', trigger: 'blur'},
        ]
      },
      forgetRules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
        ],
        mail: [
          {type: 'email', required: true, message: '请输入邮箱', trigger: 'blur'},
        ],
        verCode: [
          {required: true, message: '请输入邮箱验证码', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
        repassword: [
          {required: true, message: '请再次输入密码', trigger: 'blur'},
        ]
      },
      sendTimer: null,
    }
  },
  created() {
    this.getCookie();
  }, watch: {
    getNickName() {
      this.nickName = store.getters.nickName;
    }
  },
  computed: {
    getNickName() {
      return store.getters.nickName;
    }
  },
  methods: {
    //查询订单
    handleOrder() {
      this.$refs.orderForm.validate((valid) => {
        if (valid) {
          this.loading = true
          findOrder(this.orderForm).then(res => {
            if (res.code == 200) {
              this.orderVisible = false
              this.$modal.alertSuccess('订单查询成功，已发送至邮箱，请登录邮箱查看订单详细信息！')
            } else {
              this.$modal.msgError(res.msg)
            }
          }).finally(() => {
            this.loading = false;
          })
        }
      })
    },
    //点击找回密码
    forgetPass() {
      this.sendMsg = '发送验证码'
      this.isSend = false
      this.sendTimer = null
      this.forgetVisible = true
      this.loginVisible = false
      this.loading = false
    },
    loadCaptImg() {
      getCaptImg().then(res => {
        this.captImgUrl = res.img;
        this.userForm.uuid = res.uuid;
      })
    },
    //退出
    async loginOut() {
      this.$confirm('确定退出吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
        })
      }).catch(() => {
      });
    },
    getCookie() {
      const username = Cookies.get('username');
      const password = Cookies.get('password');
      const rememberMe = Cookies.get('rememberMe')
      this.userForm = {
        username: username === undefined ? this.userForm.username : username,
        password: password === undefined ? this.userForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
      };
    },
    handleLogin() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.userForm.rememberMe) {
            Cookies.set('username', this.userForm.username, {expires: 30});
            Cookies.set('password', encrypt(this.userForm.password), {expires: 30});
            Cookies.set('rememberMe', this.userForm.rememberMe, {expires: 30});
          } else {
            Cookies.remove('username');
            Cookies.remove('password');
            Cookies.remove('rememberMe');
          }

          this.$store.dispatch('Login', this.userForm).then(() => {
            store.dispatch('GetInfo', this.userForm).then(() => {
              this.loginVisible = false;
              this.$modal.msgSuccess('登录成功')
            }).catch(err => {
              store.dispatch('LogOut').then(() => {
                this.$modal.msgError('获取用户信息失败');
              })
            })
          }).catch(() => {
            this.loadCaptImg();
            this.loading = false;
          }).finally(() => {
            this.loading = false;
          });
        }
      });
    },
    //发送验证码
    sendCheckCode(filedForm) {
      if (!this.validateEmail(this[filedForm].mail)) {
        return
      }
      this.isSend = true
      this.sendMsg = '正在发送...'
      sendEmailCode({receiveMail: this[filedForm].mail}).then(res => {
        if (res.code == 200) {
          this.$modal.msgSuccess('邮箱发送成功')
          clearInterval(this.sendTimer)
          let timers = 120;
          this.sendTimer = setInterval(() => {
            if (timers == 0) {
              clearInterval(this.sendTimer)
              this.isSend = false
              this.sendMsg = '发送验证码'
              return
            }
            this.sendMsg = timers + '秒后重试'
            timers--;
          }, 1000)
        } else {
          this.$modal.msgError('邮箱发送失败')
        }
      })
    },
    handleForget() {
      this.$refs.forgetForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.userForm.password != this.userForm.repassword) {
            this.$modal.msgWarning('二次密码输入不一致，请重新输入');
            return
          }
          //找回密码操作
          forgetPass(this.userForm).then(res => {
            if (res.code == 200) {
              this.$modal.msgSuccess(res.msg)
              setTimeout(() => {
                this.forgetVisible = false
                this.loginVisible = true
              }, 1500)
            } else {
              this.$modal.msgError(res.msg)
            }
          }).finally(() => {
            this.loading = false;
          })
        }
      })
    },
    toRegister() {
      this.$router.push('/register')
    },
    toLogin() {
      this.loadCaptImg();
      this.loginVisible = true
      this.loading = false
    },
    showOrderSearch() {
      this.loadCaptImg();
      this.orderVisible = true
      this.loading = false
    },
    handleClose(done) {
      done()
    },
    validateEmail(value) {
      if (value === '') {
        this.$message('请正确填写邮箱')
        return false
      } else {
        let reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        if (!reg.test(value)) {
          this.$message('请输入有效的邮箱')
          return false
        }
        return true;
      }
    }, searchGoods() {
      this.$router.push({name: 'productlist', params: {code: '2'}, query: {search: this.searchValue}});
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
dl, ol, ul {
  margin-bottom: 0px;
}

ol, ul {
  padding-left: 0px;
}

h1 {
  font-size: 14px;
  color: #555;
  text-align: center;
  width: 64px;
  background: url("~@/static/image/bl.svg") no-repeat;
  background-size: 64px;
  padding-top: 40px;
  height: 50px;
  margin: 0 auto 15px
}

.footset {
  display: flex;
  overflow: hidden;
  /*background-color: #f0f0f0;*/
  /*border-left: 1px solid #ddd;*/
  /*border-bottom: 1px solid #ddd;*/
  box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 4%)
}

.header-left {
  width: 35%;
  min-width: 620px;
}

.header-center {
  width: 40%;
  min-width: 220px;
}

.header-right {
  text-align: right;
  width: 35%;
  min-width: 200px;
}

/deep/ div.search-main > input {
  padding: 3px 6px !important;
}

/deep/ .el-dialog {
  min-width: 450px;
  max-width: 500px;
}

/deep/ .el-dialog__body {
  padding: 5px 10px 20px 10px;
}
</style>
